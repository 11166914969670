import {
    createWebHistory,
    createRouter,
} from "vue-router";
import Home from "./components/Home.vue";
import { fetchToken } from "./utility";

const auth = () => {
    let token = fetchToken()
    console.log("Line 10000000000000000000000", token)
    return !!token;
};

const authenticatedOnly = (to, from, next) => {
    if (auth()) {
        next(); // allow to enter route
    } else {
        // Redirect to login page
        next(`/login?referTo=${encodeURIComponent(to.fullPath)}`);
    }
    // console.log("Line 21111111111111111111111111111111111", window.Tawk_API)

    if (to.name == "dashboard") {
        // Tawk_API.toggleVisibility()
        // Tawk_API.toggleVisibility()
    }
    // if (Tawk_API.isChatHidden()) {
    //     // Tawk_API.showWidget()
    //     Tawk_API.toggleVisibility()
    // }
};

const notForAuthenticated = (to, from, next) => {
    if (!auth()) {
        next(); // allow to enter route
    } else {
        // Redirect to dashboard main page
        next(`/dashboard`);
    }
};

const InspectionService = () =>
    import("./components/services/InspectionService.vue");
const HowItWorks = () =>
    import("./components/HowItWorks.vue");
const GetPrice = () =>
    import("./components/services/priceDisplayTemplates/GetPrice.vue");

const BlogHome = () =>
    import("./components/blog/BlogHome.vue");
const BlogArticle = () =>
    import("./components/blog/BlogArticle.vue");

const Cart = () =>
    import("./components/cart/Cart.vue");

const BecomeAnInspector = () =>
    import("./components/inspector/BecomeAnInspector.vue");
// const FindAPro = () =>
//     import("./components/dashboard/dashboardSubViews/jobs/FindAPro.vue");
const BookAPro = () =>
    import("./components/services/BookAPro.vue");

const Login = () =>
    import("./components/forms/Login.vue");
const RequestPasswordReset = () =>
    import("./components/forms/RequestPasswordReset.vue");
const Signup = () =>
    import("./components/forms/Signup.vue");
const RequestConfirmationEmail = () =>
    import("./components/forms/RequestConfirmationEmail.vue");
const EnterNewPassword = () =>
    import("./components/forms/EnterNewPassword.vue");

const InspectorRegistration = () => import("./components/inspector/inspectorRegistration/Index.vue")

const ApplicationStatus = () => import("./components/inspector/inspectorRegistration/ApplicationStatus.vue")

const Dashboard = () =>
    import("./components/dashboard/Dashboard.vue");
const DashboardHome = () =>
    import("./components/dashboard/dashboardSubViews/DashboardHome.vue");
const Wallet = () =>
    import("./components/dashboard/dashboardSubViews/Wallet.vue");
const WalletTransactions = () =>
    import("./components/dashboard/dashboardSubViews/wallet/WalletTransactions.vue");
const BankAccounts = () =>
    import("./components/dashboard/dashboardSubViews/wallet/BankAccounts.vue");

const Inbox = () =>
    import("./components/Inbox.vue");
const Calendar = () =>
    import("./components/inspector/InspectorCalendar.vue");
const Projects = () =>
    import("./components/dashboard/dashboardSubViews/Projects.vue");
const MyProjects = () =>
    import("./components/dashboard/dashboardSubViews/projects/MyProjects.vue");
const ProjectForm = () =>
    import("./components/dashboard/dashboardSubViews/projects/ProjectForm.vue");
const ProjectDetails = () =>
    import("./components/dashboard/dashboardSubViews/projects/ProjectDetails.vue");

const ReportTemplates = () =>
    import("./components/dashboard/dashboardSubViews/ReportTemplates.vue");
const MyReportTemplates = () =>
    import("./components/dashboard/dashboardSubViews/reportTemplates/MyReportTemplates.vue");
const ReportTemplateForm = () =>
    import("./components/dashboard/dashboardSubViews/reportTemplates/ReportTemplateForm.vue");
const ViewReportTemplate = () =>
    import("./components/dashboard/dashboardSubViews/reportTemplates/ViewReportTemplate.vue");

const Orders = () =>
    import("./components/dashboard/dashboardSubViews/Orders.vue");
const Jobs = () =>
    import("./components/dashboard/dashboardSubViews/Jobs.vue");
const MyJobs = () =>
    import("./components/dashboard/dashboardSubViews/jobs/MyJobs.vue");
const PostJob = () =>
    import("./components/dashboard/dashboardSubViews/jobs/PostJob.vue");
const ViewJob = () =>
    import("./components/dashboard/dashboardSubViews/jobs/ViewJob.vue");
const FindPros = () =>
    import("./components/dashboard/dashboardSubViews/jobs/FindPros.vue");
const FindJobs = () =>
    import("./components/dashboard/dashboardSubViews/jobs/FindJobs.vue");

const Subscriptions = () =>
    import("./components/dashboard/dashboardSubViews/Subscriptions.vue");
const SubscriptionPlans = () =>
    import("./components/dashboard/dashboardSubViews/subscriptions/SubscriptionPlans.vue");
const MySubscriptionPlan = () =>
    import("./components/dashboard/dashboardSubViews/subscriptions/MySubscriptionPlan.vue");

const Settings = () =>
    import("./components/dashboard/dashboardSubViews/Settings.vue");
const ProfileSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/ProfileSettings.vue");
const InspectorProfile = () =>
    import("./components/dashboard/dashboardSubViews/settings/InspectorProfile.vue");
const TwoFactorAuth = () =>
    import("./components/dashboard/dashboardSubViews/settings/TwoFactorAuth.vue");
const Referral = () =>
    import("./components/dashboard/dashboardSubViews/settings/Referral.vue");
const PasswordSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/PasswordSettings.vue");
const NotificationSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/NotificationSettings.vue");
const PaymentSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/PaymentSettings.vue");

const MyOrders = () =>
    import("./components/dashboard/myOrders/MyOrders.vue");
const MyOrderDetails = () =>
    import("./components/dashboard/myOrders/MyOrderDetails.vue");

const InspectionReportMainView = () =>
    import("./components/inspector/inspectorReport/InspectionReportMainView.vue");

const InspectorJobNotification = () =>
    import("./components/inspector/inspectorJob/InspectorJobNotification.vue");

// const InspectorCalendar = () => import("./components/inspector/InspectorCalendar.vue")

const PasswordResetLinkSent = () =>
    import("./components/staticStatusPages/PasswordResetLinkSent.vue");
const EmailConfirmation = () =>
    import("./components/staticStatusPages/EmailConfirmation.vue");
const BookingSuccessful = () =>
    import("./components/staticStatusPages/BookingSuccessful.vue");
const BookingCanceled = () =>
    import("./components/staticStatusPages/BookingCanceled.vue");

const MainViewVehicleInspectionReport = () =>
    import(
        "./components/reportViewTemplates/vehicleInspectionReport/MainViewVehicleInspectionReport.vue"
    );

const WriteReview = () =>
    import("./components/customerReview/WriteReview.vue");

const ReferAnInspector = () =>
    import("./components/referral/ReferAnInspector.vue");

const Playground = () =>
    import("./components/Playground.vue");

const NotificationsPage = () =>
    import("./components/NotificationsPage.vue");

const RegisterAsBusiness = () =>
    import("./components/business/RegisterAsBusiness.vue");

const Help = () =>
    import("./components/help/Help.vue");

const PrivacyPolicy = () =>
    import("./components/policyDocumentations/PrivacyPolicy.vue");
const TermsOfService = () =>
    import("./components/policyDocumentations/TermsOfService.vue");
const CookiePolicy = () =>
    import("./components/policyDocumentations/CookiePolicy.vue");
const AboutUs = () =>
    import("./components/AboutUs.vue")

const JobMarketPlace = () =>
    import("./components/JobMarketPlace.vue");

const VehicleListings = () =>
    import("./components/marketplace/vehicle/VehicleListings.vue");
const VehiclePage = () =>
    import("./components/marketplace/vehicle/VehiclePage.vue");
const CreateVehicleListing = () =>
    import("./components/marketplace/vehicle/CreateVehicleListing.vue");
const EditVehicleListing = () =>
    import("./components/marketplace/vehicle/EditVehicleListing.vue")
const PropertyListings = () =>
    import("./components/marketplace/property/PropertyListings.vue")
const ViewPropertyListing = () =>
    import("./components/marketplace/property/ViewPropertyListing.vue")
const CreatePropertyListing = () =>
    import("./components/marketplace/property/CreatePropertyListing.vue")
const EditPropertyListing = () =>
    import("./components/marketplace/property/EditPropertyListing.vue")

const routes = [
    { path: "/", component: Home },
    { path: "/blog", component: BlogHome },
    { path: "/blog/:slug", component: BlogArticle },
    {
        path: "/cart",
        component: Cart,
        beforeEnter: authenticatedOnly,
    },
    { path: "/inspector", component: BecomeAnInspector },
    // {
    //     path: "/find-a-pro",
    //     component: FindAPro,
    //     beforeEnter: authenticatedOnly,
    // },
    // { path: "find-jobs", component: FindJobs, beforeEnter: authenticatedOnly },
    {
        path: "/book-a-pro",
        component: BookAPro,
        beforeEnter: authenticatedOnly,
    },

    { path: "/login", component: Login, beforeEnter: notForAuthenticated },
    {
        path: "/request-confirmation-email",
        component: RequestConfirmationEmail,
        beforeEnter: notForAuthenticated,
    },
    { path: "/signup", component: Signup, beforeEnter: notForAuthenticated },
    {
        path: "/resetpassword",
        component: RequestPasswordReset,
        beforeEnter: notForAuthenticated,
    },
    {
        path: "/enter-new-password",
        component: EnterNewPassword,
        beforeEnter: notForAuthenticated,
    },

    // { path: "services/property", component: HomeInspection },
    // { path: "services/:serviceType", component: VehicleInspection },
    { path: "/services/:serviceType", component: InspectionService },
    { path: "/how-it-works", component: HowItWorks },
    { path: "/services/get-price/:inspectionType", component: GetPrice },

    { path: "/inspector/inspector-registration/:visibleComponent", component: InspectorRegistration, beforeEnter: authenticatedOnly },

    { path: "/inspector/inspector-registration/application-status", component: ApplicationStatus, beforeEnter: authenticatedOnly },
    {
        path: "/dashboard",
        component: Dashboard,
        beforeEnter: authenticatedOnly,
        children: [
            { path: "", component: DashboardHome },
            {
                path: "wallet", component: Wallet, children: [
                    { path: "", component: WalletTransactions },
                    { path: "wallet-transactions", component: WalletTransactions },
                    { path: "bank-accounts", component: BankAccounts },
                ]
            },
            {
                path: "messages", component: Inbox
            },
            {
                path: "calendar", component: Calendar
            },
            {
                path: "projects", component: Projects,
                children: [
                    { path: "", component: MyProjects },
                    { path: "new", component: ProjectForm },
                    { path: "edit/:projectId", component: ProjectForm },
                    {
                        path: "show/:projectId", component: ProjectDetails, children: [
                            {
                                path: "", component: ProjectDetails,
                                path: ":projectDetailsComponent", component: ProjectDetails,
                            }
                        ]

                    },
                ]
            },
            {
                path: "report-templates", component: ReportTemplates,
                children: [
                    { path: "", component: MyReportTemplates },
                    { path: "new", component: ReportTemplateForm },
                    { path: "edit/:templateId", component: ReportTemplateForm },
                    { path: "show/:templateId", component: ViewReportTemplate },
                ]
            },
            {
                path: "orders", component: Orders
            },
            {
                path: "jobs", component: Jobs,
                children: [
                    { path: "", component: MyJobs },
                    { path: "my-jobs", component: MyJobs },
                    { path: "find-pros", component: FindPros },
                    { path: "find-jobs", component: FindJobs },
                    { path: "post-job", component: PostJob },
                    { path: "edit-job/:jobId", component: PostJob },
                    { path: "view-job/:jobId", component: ViewJob },
                ]
            },
            {
                path: "subscriptions", component: Subscriptions,
                children: [
                    { path: "", component: SubscriptionPlans },
                    { path: "subscription-plans", component: SubscriptionPlans },
                    { path: "my-subscription-plan", component: MySubscriptionPlan },
                ]
            },
            {
                path: "settings", component: Settings, children: [
                    { path: "", component: ProfileSettings },
                    { path: "user-profile", component: ProfileSettings },
                    { path: "inspector-profile", component: InspectorProfile },
                    { path: "two-factor", component: TwoFactorAuth },
                    { path: "referral", component: Referral },
                    { path: "password-settings", component: PasswordSettings },
                    { path: "notification-settings", component: NotificationSettings },
                    { path: "payment-settings", component: PaymentSettings },
                ]
            },
        ]
    },
    // {
    //     path: "/dashboard/orders",
    //     component: MyOrders,
    //     beforeEnter: authenticatedOnly,
    // },
    // {
    //     path: "/dashboard/order-details/:orderId",
    //     component: MyOrderDetails,
    //     beforeEnter: authenticatedOnly,
    // },

    // {
    //     path: "/inspector/inspector-report/:jobId",
    //     component: InspectionReportMainView,
    //     beforeEnter: authenticatedOnly,
    // },

    // {
    //     path: "/inspector/inspector-jobs",
    //     component: MyJobs,
    //     beforeEnter: authenticatedOnly,
    // },
    // {
    //     path: "/inspector/inspector-job-notification",
    //     component: InspectorJobNotification,
    //     beforeEnter: authenticatedOnly,
    // },
    { path: "/status/confirm-email", component: EmailConfirmation },
    { path: "/status/sent-password-reset", component: PasswordResetLinkSent },
    {
        path: "/status/booking-successful",
        component: BookingSuccessful,
        beforeEnter: authenticatedOnly,
    },
    {
        path: "/status/booking-canceled",
        component: BookingCanceled,
        beforeEnter: authenticatedOnly,
    },

    // { path: "inspector/inspector-calendar", component: InspectorCalendar, beforeEnter: authenticatedOnly },

    {
        path: "/view-report/vehicle-inspection/:orderId",
        component: MainViewVehicleInspectionReport,
        beforeEnter: authenticatedOnly,
    },

    {
        path: "/write-a-review/:orderId",
        component: WriteReview,
        beforeEnter: authenticatedOnly,
    },

    { path: "/refer-an-inspector", component: ReferAnInspector },

    { path: "/playground", component: Playground },

    { path: "/notifications", component: NotificationsPage },

    { path: "/business/register", component: RegisterAsBusiness },

    { path: "/help", component: Help },

    { path: "/privacy", component: PrivacyPolicy },
    { path: "/terms", component: TermsOfService },
    { path: "/cookie-policy", component: CookiePolicy },
    { path: "/about-us", component: AboutUs },

    { path: "/job-market-place", component: JobMarketPlace },

    { path: "/market-place/vehicle-listings", component: VehicleListings },
    { path: "/market-place/vehicle/:id", component: VehiclePage },
    {
        path: "/market-place/create-vehicle-listing",
        component: CreateVehicleListing,
    },
    {
        path: "/market-place/edit-vehicle-listing/:id",
        component: EditVehicleListing,
    },

    { path: "/market-place/property-listings", component: PropertyListings },
    {
        path: "/market-place/view-property/:id",
        component: ViewPropertyListing,
    },
    {
        path: "/market-place/create-property-listing",
        component: CreatePropertyListing,
    },
    {
        path: "/market-place/edit-property-listing/:id",
        component: EditPropertyListing,
    },
];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

export default router;