import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to create a subscription
  CREATE_SUBSCRIPTION({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/create-a-subscription", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a upgrade price
  GET_UPGRADE_PRICE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/subscriptions/get-plan-upgrade-price/${payload.currentSubscriptionId}/${payload.newPlanName}/${payload.noOfMonths}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //For a customer to get a renewal price
  GET_RENEWAL_PRICE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/subscriptions/get-subscription-renewal-price/${payload.subscriptionId}?noOfMonths=${payload.noOfMonths}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //For a customer to upgrade to a new plan
  UPGRADE_SUBSCRIPTION({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/upgrade-subscription", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to renew a plan
  RENEW_SUBSCRIPTION({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/renew-a-subscription", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //For a customer to pay for addons
  PAY_FOR_ADDONS({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/pay-for-addons", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
